import { Component, OnInit, OnDestroy, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { DataService } from '../app/data.service';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective) datatableElement!: DataTableDirective;

  searchIdEmpresa: string = '';
  dtOptions: DataTables.Settings = {};
  datos: any[] = [];
  dtTrigger: Subject<any> = new Subject();

  constructor(private dataService: DataService, private el: ElementRef) { }

  ngOnInit(): void {
    this.dtOptions = {
      searching: true,
      pagingType: 'full_numbers',
      pageLength: 50,
      ordering: false,
      language: {
        lengthMenu: 'Mostrar _MENU_ registros por página',
        zeroRecords: 'No se encontraron registros',
        info: 'Mostrando _START_ a _END_ de _TOTAL_ registros',
        infoEmpty: 'Mostrando 0 a 0 de 0 registros',
        infoFiltered: '(filtrado de _MAX_ registros totales)',
        search: 'Buscar:',
        paginate: {
          first: 'Primero',
          previous: 'Anterior',
          next: 'Siguiente',
          last: 'Último'
        }
      },
      columns: [
        { data: 'idempresa' },
        { data: 'nombrerazon' },
        { data: 'Documento' },
        { data: 'fecha_emision' },
        { data: 'Fecha_Max' },
        { data: 'Estado' },
        { data: 'anulado' },
        { data: 'NroRegistros' }
      ],
      initComplete: (dtInstance: any, settings: any) => {
        console.log("initComplete is running");
        this.setupColumnSearch(dtInstance);
      }
    };

    this.loadData();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    if (this.datatableElement && this.datatableElement.dtInstance) {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
    }
  }

  ngAfterViewInit(): void {
    // Inicializa los campos de búsqueda después de que la vista se ha inicializado completamente
    this.initSearchFields();
  }

  private initSearchFields(): void {
    const inputElements = this.el.nativeElement.querySelectorAll('.search-input');

    inputElements.forEach((input: any, index: number) => {
      input.addEventListener('input', () => {
        if (this.datatableElement.dtInstance) {
          this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            console.log(`input event fired for column ${index}`);
            dtInstance.column(index).search(input.value).draw();
          });
        }
      });
    });
  }


  private loadData(): void {
    this.dataService.getData().subscribe((data) => {
      console.log(data);

      if (this.datatableElement.dtInstance) {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.clear().rows.add(data).draw(); // Actualizamos los datos
        });
      } else {
        this.datos = data;
        this.dtTrigger.next();
      }
    });
  }

  private setupColumnSearch(dtInstance: any): void {
    if (dtInstance && dtInstance.api) {
      // DataTable se está inicializando de forma asincrónica
      dtInstance.then((dtInstance: DataTables.Api) => {
        this.setupColumnSearchLogic(dtInstance);
      });
    } else if (dtInstance && dtInstance.columns) {
      // DataTable ya está inicializado
      this.setupColumnSearchLogic(dtInstance);
    }
  }

  private setupColumnSearchLogic(dtInstance: DataTables.Api): void {
    dtInstance.columns().every((index: number) => {
      const inputModel = this.findModelByName(`search${index}Model`);
      if (inputModel) {
        inputModel.valueChanges.subscribe((value: string) => {
          console.log(`input event fired for column ${index}`);
          dtInstance.column(index).search(value).draw();
        });
      }
    });
  }

  private findModelByName(name: string): NgModel | undefined {
    return Object.keys(this).find(key => key === name) ? (this as any)[name] : undefined;
  }

  isCenteredColumn(columnName: string): boolean {
    const centeredColumns = ['idempresa', 'Documento', 'fecha_emision', 'Fecha_Max', 'Estado', 'anulado', 'NroRegistros'];
    return centeredColumns.includes(columnName);
  }

  isRightAlignedColumn(columnName: string): boolean {
    const rightAlignedColumns = ['Documento'];
    return rightAlignedColumns.includes(columnName);
  }


}
